export enum Language {
  // English
  ENGLISH = "en",
  ENGLISH_STUPEFIED = "en_US",

  // Foreign Languages

  GERMAN = "de",
  RUSSIAN = "ru",
  CHINESE_SIMPLIFIED = "zh_Hans",

  // Constructed Languages
  //TOKIPONA = "tokipona",
  //ESPERANTO = "esperanto",

  // Joke Languages
  //OWO = "owo",
  //PIRATE = "pr",
  //BOTTOM = "bottom",
  //LEET = "leet",
  //PIGLATIN = "piglatin",
  //ENCHANTMENT_TABLE = "enchantment",
}

export interface LanguageEntry {
  /**
   * What should appear in the UI as the name for the language
   */
  display: string;

  /**
   * What emoji should be displayed
   */
  emoji: string;

  /**
   * Filename of the related language file
   */
  i18n: string;

  /**
   * Dayjs locale file if different
   */
  dayjs?: string;

  /**
   * Whether the UI should be right-to-left
   */
  rtl?: boolean;

  /**
   * Whether the language is a conlang (constructed language) or a joke
   */
  cat?: "const" | "alt";

  /**
   * Whether the language has a maintainer
   * (patched in)
   */
  verified?: boolean;

  /**
   * Whether the language is incomplete
   * (patched in)
   */
  incomplete?: boolean;
}

export const Languages: { [key in Language]: LanguageEntry } = {
  // English and "English"
  en: {
    display: "English (Traditional)",
    emoji: "🇬🇧",
    i18n: "en",
    dayjs: "en-gb",
  },
  en_US: {
    display: "English (Simplified)",
    emoji: "🇺🇸",
    i18n: "en_US",
    dayjs: "en",
  },

  // Foreign languages 

  de: { display: "Deutsch", emoji: "🇩🇪", i18n: "de", verified: true },
  
  ru: { display: "Русский", emoji: "🇷🇺", i18n: "ru", verified: true },
  zh_Hans: {
    display: "简体中文",
    emoji: "🇨🇳",
    i18n: "zh_Hans",
    dayjs: "zh",
  },

  // Constructed languages
};
